// Dependencies
import { Flex, Spinner, Stack, useToast } from '@chakra-ui/core';
import classnames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

// Apis
import { ProductApi } from '../../apis';
// Component
import { Alert } from '../../components';
// Constants
import { ROUTES } from '../../constants';
import { PRODUCT_TYPE } from '../../shared/enums';
// Interfaces
import { ProductModel } from '../../shared/models';
// Utils
import { TabTitle, metaTagByDesc, metaTagByKey, metaTagByTitle, metaTagByWeb } from '../../utils/generaltittlefunction';
// Styles
import './styles.scss';
import { CardSocialDescription, DetailsSection, PrimaryCard } from '../../components/Card/BannerProductCard';
import { CommonCardOutlet } from '../../components/Card/CommonCardOutlet';
import { useParams } from 'react-router-dom';

// Export merchandise page
export const MerchandisePage: FC = () => {
  // States
  const [product, setProduct] = useState<ProductModel>();
  const [similarProducts, setSimilarProducts] = useState<ProductModel[]>([])

  // Get toast from hook
  const toast = useToast();
  const { t } = useTranslation();
  const { id: productId } = useParams<{ id: string }>();

  // Get history from hook
  const history = useHistory();

  // Fetch products
  const fetchProducts = useCallback(() => {
    ProductApi.read(productId)
      .then((res) => {
        setProduct(res.product);
      })
      .catch((err) => {
        toast({
          position: 'top-right',
          render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
        });
      });
    ProductApi.readAll({ query: { type: PRODUCT_TYPE.MERCHANDISE } })
      .then((res) => {
        setSimilarProducts(res.products);
      })
      .catch((err) => {
        console.log("err=>", err);
      });
  }, []);

  // On price range, count per page, page number, sort order changed
  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line
  }, [productId]);

  useEffect(() => {
    fetchProducts();
  }, []);
  //dynamic handle title
  if (history.location.pathname === ROUTES.MERCHANDISE.INDEX) {
    TabTitle(t('Common.Merchandise - Digital Music Shopping Market Place'));
  }

  if (history.location.pathname === ROUTES.MERCHANDISE.INDEX) {
    metaTagByTitle(t('Common.Merchandise - Digital Music Shopping Market Place'));
  }
  metaTagByDesc(
    t('Common.D-Music is founded on values we all share and are ready to stand for.') +
    ' ' +
    t('Common.They bring us together well beyond our current products and technologies.') +
    ' ' +
    t(
      'Common.They’ve defined our identity since the beginning, and they’ll continue to do so, no matter how our business evolves.'
    )
  );
  metaTagByKey(t('Common.Dmusic, Nft, Hackers, Explore Through the Most Exciting Music NFT'));
  metaTagByWeb(`https://dev.dmusic.io${window.location.pathname}`);
  const tag = product?.category.map(item => item.name)

  return (
    <div className="song-container">
      <div className="crypto-art-container">
        <div className="right-container">
          <CardSocialDescription
            tags={tag}
            sign={product?.sign?.url}
            title="Merchandise Art"
            subTitle={product?.name}
            artistDetails={product?.artistDetails}
            description={product?.description}
          />
        </div>
        <div className="left-container" style={{ justifyContent: 'end' }}>
          <div className='card'>
            {/*  eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <PrimaryCard details={product} />
          </div>
        </div>
      </div>
      <Stack mt={'3%'} width={'80%'} alignSelf={'left'}>
        <h1 className="text-heading1">Similar Product</h1>
      </Stack>
      <div className="similar-track-container">
        {similarProducts?.slice(0, 9).map((item) => {
          return (
            <Stack h={[400, 400, 400, 450, 550]}>
               <PrimaryCard details={item} />
            </Stack>
          )
        })}
      </div>
    </div>
  );
};
