import { Flex, Spinner, Stack, useToast } from '@chakra-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ProductApi } from '../../apis';
import { Alert, AnimationOnScroll } from '../../components';
import { REACT_APP_API_ASSET_SERVER } from '../../constants';
import { PRODUCT_TYPE } from '../../shared/enums';
import { ProductModel } from '../../shared/models';
import './styles.scss';
import { CommunityEventDescriptionCard, CommunityEventImageCard, DetailsSection } from '../../components/Card/BannerProductCard';
import { CommonCardOutlet } from '../../components/Card/CommonCardOutlet';
import { SuggestionSection } from '../../components/Card/TopCollectionCard';
import moment from 'moment';


export const EventsPage: FC = () => {
  const [product, setProduct] = useState<ProductModel>();
  const [similarProducts, setSimilarProducts] = useState<ProductModel[]>([]);
  const [pastEvents, setPastEvents] = useState<ProductModel[]>([]);
  const [futureEvents, setFutureEvents] = useState<ProductModel[]>([]);
  const [isLoading, setIsLoding] = useState<boolean>()
  // Get product id from hook
  const { id: productId } = useParams<{ id: string }>();

  const toast = useToast();

  // Data fetcher
  const fetchData = (productId: string) => {
    setIsLoding(true)
    ProductApi.read(productId)
      .then((res) => {
        setProduct(new ProductModel(res.product));
      })
      .catch((err) => {
        toast({
          position: 'top-right',
          render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
        });
      });
    ProductApi.readAll({ query: { type: PRODUCT_TYPE.VIRTUAL_EVENT } })
      .then((res) => {
        setIsLoding(false)
        setSimilarProducts(res.products);
        const todayDate = moment();
        const pastEvent: ProductModel[] = [];
        const futureEvent: ProductModel[] = [];
        res.products.map((event: any) => {
          const startDate = moment(event.startTime);
          const endDate = moment(event.endTime);
          if (todayDate.isBefore(startDate)) {
            // Event is in the future
            futureEvent.push(event);
          } else if (todayDate.isSameOrAfter(startDate) && todayDate.isSameOrBefore(endDate)) {
            // Event is ongoing
            futureEvent.push(event);
          } else {
            // Event is in the past
            pastEvent.push(event);
          }
        })
        setFutureEvents(futureEvent)
        setPastEvents(pastEvent)
      })
      .catch((err) => {
        setIsLoding(false)
        console.log("err=>", err);
      });

  };
  // On product id changed
  useEffect(() => {
    fetchData(productId);
    // eslint-disable-next-line
  }, [productId]);

  return (
    <div className={'event-page'}>
      <div className="crypto-art-container">
        <div className="right-container">
          <CommunityEventDescriptionCard
            tags={product?.categoryNamesArray}
            title={product?.name}
            artistDetails={product?.artistDetails}
            description={product?.description} />
        </div>
        <div className="left-container">
          {product ?
          <div className='card'>
            <CommunityEventImageCard details={product} />
            </div>
            : null
          }
        </div>
      </div>
      <Stack w={'80%'}>
        <SuggestionSection
          futureData={futureEvents}
          pastData={pastEvents}
          titleName='Upcoming Events'
          historyTitleName={'Event History'} />
      </Stack>
      <Stack w={'80%'} my={'3%'} justifyContent={'start'}>
        <h1 className='text-heading1'>Trending Events</h1>
      </Stack>
      <div className="content">
        {isLoading ? (
          <Flex justifyContent="center" width={'100%'} alignItems="center" height={200}>
            <Spinner color="#00Ff00" size="xl" />
          </Flex>
        ) : (
          <div className="products-container">
            {similarProducts.length > 0 &&
              similarProducts.map((product) => (
                <AnimationOnScroll key={product.id} animation="animate__fadeIn" isSubElement>
                  <Stack h={[400, 400, 400, 450, 550]}>
                    <CommunityEventImageCard details={product} />
                  </Stack>
                </AnimationOnScroll>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
