import { Flex, Spinner, Stack, useToast } from '@chakra-ui/core';
import React, { FC, useCallback, useEffect, useState } from 'react';

import { UserApi } from '../../../../apis';
import { Alert, AnimationOnScroll, Icon, ProductCard } from '../../../../components';
import { IArtist, IProduct } from '../../../../shared/interfaces';
import { ProductModel } from '../../../../shared/models';
import {
    TabTitle,
    metaTagByDesc,
    metaTagByKey,
    metaTagByTitle,
    metaTagByWeb
} from '../../../../utils/generaltittlefunction';
import './styles.scss';
import { useSelector } from 'react-redux';
import { getUser } from '../../../../store/selectors';
import { CommonCardOutlet } from '../../../../components/Card/CommonCardOutlet';
import { Currency } from '../../../../assets/icons';
import { BiHeart, BiSolidShareAlt } from 'react-icons/bi';

export const SubscribedArtistTab: FC = () => {
    const [subscribedArtist, setSubscribedArtist] = useState<IArtist[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const user = useSelector(getUser)

    const toast = useToast();

    const fetchSubscribedArtist = useCallback(() => {
        setLoading(true);
        UserApi.getSubscribed(user?.id as string)
            .then((res) => {
                setSubscribedArtist(res.subscribedArtist.map((product: IProduct) => new ProductModel(product)));
                setLoading(false);
            })
            .catch((err) => {
                toast({
                    position: 'top-right',
                    render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
                });
            })
            .finally(() => setLoading(false));
    }, [toast]);

    useEffect(() => {
        if(user){
            fetchSubscribedArtist();
        }
    }, [user]);

    TabTitle('Subscribed Artist- Digital Music Shopping Market Place');
    metaTagByTitle('Subscribed Artist - Digital Music Shopping Market Place');
    metaTagByDesc(
        'D-Music is founded on values we all share and are ready to stand for. They bring us together well beyond our current products and technologies. They’ve defined our identity since the beginning, and they’ll continue to do so, no matter how our business evolves.'
    );
    metaTagByKey('Dmusic, Nft, Hackers, Explore Through the Most Exciting Music NFT');
    metaTagByWeb(`https://dev.dmusic.io${window.location.pathname}`);

    return (
        <>
            {isLoading ? (
                <Flex justifyContent="center" alignItems="center" height={200}>
                    <Spinner color="#00Ff00" size="xl" />
                </Flex>
            ) : (
                <div className="liked-products-tab">
                    {subscribedArtist.map(({ id, name, thumbnail }, index) => (
                        <Stack height={400}>
                        <CommonCardOutlet image={thumbnail} id={id as string} productType={'artist'} type="collection">
                          <Stack h={'100%'} w='100%'>
                            <Stack w='100%' display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                              <Stack w='50%'> <p>{name}</p> </Stack>
                              <Stack w='20%' display={'flex'} flexDirection={'row'} justifyContent={'space-between'} ><BiHeart color='white' /> <BiSolidShareAlt color='white' /></Stack>
                            </Stack>
                          </Stack>
                        </CommonCardOutlet>
                      </Stack>
                    ))}
                </div>
            )}
        </>
    );
};


