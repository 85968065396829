import React, { FC, useEffect, useState } from 'react';
import { ArtistApi } from '../../../apis';
import { Pagination } from '../../../components';
import { IArtist } from '../../../shared/interfaces';
import './styles.scss';
import { Stack } from '@chakra-ui/core';
import { CommonCardOutlet } from '../../../components/Card/CommonCardOutlet';
import { BiHeart, BiSolidShareAlt } from 'react-icons/bi';
import { Currency } from '../../../assets/icons';

const pageLimit = 12;

export const ArtistListPage: FC = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [artists, setArtists] = useState<IArtist[]>([]);
  const [pageCnt, setPageCnt] = useState(0);

  useEffect(() => {
    ArtistApi.readAll({
      options: {
        limit: pageLimit,
        skip: (pageNumber - 1) * pageLimit,
        isFeatured: true
      }
    })
      .then((res) => {
        setArtists(res.artists);
        setPageCnt(Math.ceil(res.pagination.total / pageLimit));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pageNumber]);

  return (
    <div className="artist-list-page">
      <div className="content">
        <div className="artists">
          {artists.map(({ id, name, thumbnail }, index) => (
            <Stack height={400}>
              <CommonCardOutlet image={thumbnail} id={id as string} productType={'artist'} type="collection">
                <Stack h={'100%'} w='100%'>
                  <Stack w='100%' display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Stack w='50%'> <p>{name}</p> </Stack>
                    <Stack w='20%' display={'flex'} flexDirection={'row'} justifyContent={'space-between'} ><BiHeart color='white' /> <BiSolidShareAlt color='white' /></Stack>
                  </Stack>
                </Stack>
              </CommonCardOutlet>
            </Stack>
          ))}
        </div>
        <Pagination pageCnt={pageCnt} activePage={pageNumber} onChange={setPageNumber} />
      </div>
    </div>
  );
};
