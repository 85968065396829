import React, { useEffect, useState } from "react";
import { TabTitle, metaTagByDesc, metaTagByKey, metaTagByTitle, metaTagByWeb } from "../../utils/generaltittlefunction";
import { REACT_APP_API_ASSET_SERVER, ROUTES } from "../../constants";
import { ProductApi } from "../../apis";
import { ProductModel } from "../../shared/models";
import { Flex, Spinner, Stack, useToast } from "@chakra-ui/core";
import { useHistory, useParams } from "react-router-dom";

import { Alert } from "../../components";
import { CardSocialDescription, DetailsSection, PrimaryCard } from "../../components/Card/BannerProductCard";
import './styles.scss'
import { ArtistCard } from "../../components/Card/ArtistCard";

import { CommonCardOutlet } from "../../components/Card/CommonCardOutlet";
import { useTranslation } from "react-i18next";
import { PRODUCT_TYPE } from "../../shared/enums";


export const SongPage = () => {
    const [product, setProduct] = useState<ProductModel>();
    const [similarProducts, setSimilarProducts] = useState<ProductModel[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    // Get product id from hook
    const { id: productId } = useParams<{ id: string }>();

    const toast = useToast();
    const { t } = useTranslation();

    const history = useHistory()
    // Data fetcher
    const fetchData = (productId: string) => {
        setLoading(true)
        ProductApi.read(productId)
            .then((res) => {
                setProduct(res.product);
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
                toast({
                    position: 'top-right',
                    render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
                });
            });
            ProductApi.readAll({ query: { type: PRODUCT_TYPE.SONG } })
            .then((res) => {
              setLoading(false)
              setSimilarProducts(res.products);
            })
            .catch((err) => {
              setLoading(false)
              console.log("err=>", err);
            });
    };

    // On product id changed
    useEffect(() => {
        fetchData(productId);
        // eslint-disable-next-line
    }, [productId]);

    if (history.location.pathname === ROUTES.SHOP.INDEX) {
        TabTitle(t('Common.Shop - Digital Music Shopping Market Place'));
    } else if (history.location.pathname === ROUTES.EVENTS.INDEX) {
        TabTitle(t('Common.Events - Digital Music Shopping Market Place'));
    } else if (history.location.pathname === ROUTES.SONGS.INDEX) {
        TabTitle(t('Common.Songs - Digital Music Shopping Market Place'));
    } else {
        TabTitle(t('Common.Products - Digital Music Shopping Market Place'));
    }

    if (history.location.pathname === ROUTES.SHOP.INDEX) {
        metaTagByTitle(t('Common.Shop - Digital Music Shopping Market Place'));
    } else if (history.location.pathname === ROUTES.EVENTS.INDEX) {
        metaTagByTitle(t('Common.Events - Digital Music Shopping Market Place'));
    } else if (history.location.pathname === ROUTES.SONGS.INDEX) {
        metaTagByTitle(t('Common.Songs - Digital Music Shopping Market Place'));
    } else {
        metaTagByTitle(t('Common.Products - Digital Music Shopping Market Place'));
    }
    metaTagByDesc(
        t('Common.D-Music is founded on values we all share and are ready to stand for.') +
        ' ' +
        t('Common.They bring us together well beyond our current products and technologies.') +
        ' ' +
        t(
            'Common.They’ve defined our identity since the beginning, and they’ll continue to do so, no matter how our business evolves.'
        )
    );
    metaTagByKey(t('Common.Dmusic, Nft, Hackers, Explore Through the Most Exciting Music NFT'));
    metaTagByWeb(`https://dev.dmusic.io${window.location.pathname}`);
    const tag = product?.category.map(item => item.name )
    return (
        <div className="song-container">
            {loading ?
                <Flex justifyContent="center" width={'100%'} alignItems="center" height={200}>
                    <Spinner color="#00Ff00" size="xl" />
                </Flex> :
                <div className="crypto-art-container">
                    <div className="right-container">
                        <CardSocialDescription
                            tags={tag}
                            sign={product?.sign?.url}
                            title="Music Art"
                            artistDetails={product?.artistDetails}
                            subTitle={product?.name}
                            description={product?.description}
                        />
                    </div>
                    <div className="left-container" style={{ justifyContent: 'end' }}>
                        <div className='card'>
                            {/*  eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                            {/* @ts-ignore */}
                            <PrimaryCard details={product} />
                        </div>
                    </div>
                </div>
                }

            <div className="artist-state-container">
                <ArtistCard  artistDetails={product?.artistDetails} statement={product?.statement} sign={product?.sign?.url}/>
            </div>
            <Stack mt={'3%'} width={'80%'} alignSelf={'left'}>
                <h1 className="text-heading1">Similar Tracks</h1>
            </Stack>
            <div className="similar-track-container">
                {similarProducts?.slice(0, 9).map((item: ProductModel) => {
                    return (
                        <Stack h={[400, 400, 400, 450, 550]}>
                            <CommonCardOutlet image={`${REACT_APP_API_ASSET_SERVER}/${item.thumbnail.fieldname}/${item.thumbnail.filename}`} id={item.id as string} productType={item.type} type="product">
                                <DetailsSection details={item} />
                            </CommonCardOutlet>
                        </Stack>
                    )
                })}
            </div>
        </div>
    )
}