// Dependencies
import { Button, ButtonProps, useToast } from '@chakra-ui/core';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Apis
import { CartApi } from '../../../apis';
// Components
import { Alert, Icon } from '../..';
// Interfaces
import { CURRENCY } from '../../../shared/enums';
// Store
import { addToCart } from '../../../store/actions';
import { getCart, getUser } from '../../../store/selectors';
// Styles
import './styles.scss';
import { PrimaryButton } from '../PrimaryButton';

interface ICartButtonProps {
  productId: string;
  productPrice: number;
  productCurrency: CURRENCY;
  color?: 'lime' | 'cyan';
  currency?: string;
}

// Create cart button
export const CartButton: FC<ICartButtonProps> = ({
  currency,
  productId,
  productPrice,
  productCurrency,
  color = 'lime',
}) => {
  // Get cart from store
  const cart = useSelector(getCart);

  // Get user from store
  const user = useSelector(getUser);

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get toast from hook
  const toast = useToast();
  const { t } = useTranslation();

  // Check product exists in cart
  const isInCart = useMemo(() => {
    let flag = false;
    if (cart && cart.products && cart.products.length > 0) {
      flag = cart.products.some(({ id }) => id === productId);
    }

    return flag;
  }, [productId, cart]);

  // Toggle cart handler
  const handleToggleCart = () => {
    if (user) {
      if (isInCart) {
        CartApi.removeFromCart({
          productId: productId,
          fingerprint: user.id,
          userId: user.id
        })
          .then((res) => {
            dispatch(addToCart(res.cart));
            toast({
              position: 'top-right',
              render: ({ onClose }) => (
                <Alert message={t('Message.Removed from your cart!')} color="yellow" onClose={onClose} />
              )
            });
          })
          .catch((err) => {
            toast({
              position: 'top-right',
              render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
            });
          });
      } else {
        CartApi.addToCart({
          productId: productId,
          fingerprint: user.id,
          price: productPrice,
          currency: productCurrency,
          userId: user.id
        })
          .then((res) => {
            dispatch(addToCart(res.cart));
            toast({
              position: 'top-right',
              render: ({ onClose }) => (
                <Alert message={t('Message.Successfully added to the cart!')} onClose={onClose} />
              )
            });
          })
          .catch((err) => {
            toast({
              position: 'top-right',
              render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
            });
          });
      }
    } else {
      toast({
        position: 'top-right',
        render: ({ onClose }) => (
          <Alert message={t('Message.Login to the platform first!')} color="cyan" onClose={onClose} />
        )
      });
    }
  };

  // Return cart button
  return (
    <PrimaryButton scheme='primary' onClick={handleToggleCart}>
      <p className='text-body1'>
      {isInCart ? t('Common.Added to Cart') : t('Common.Add to Cart')}
      </p>
    </PrimaryButton>
  );
};
