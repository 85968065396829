import React from "react";
import './styles.scss'
import Quotes from "../../../assets/images/“.png"
import signature from "../../../assets/images/signature.png"
import { Stack } from "@chakra-ui/core";
import { BiLogoFacebook, BiLogoInstagram, BiLogoSpotify } from "react-icons/bi";
import { SiGooglechat } from 'react-icons/si'
import { LiaShareAltSolid } from 'react-icons/lia'
import { IArtist } from "../../../shared/interfaces";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants";
interface IArtistCard {
    statement?: string;
    sign?: any;
    artistDetails?: IArtist
}
export const ArtistCard = ({ statement, sign, artistDetails }: IArtistCard) => {
    return (
        <div className="artist-statement-container">
            <img src={Quotes} />
            <Stack my={15}>
                <h3 className="text-heading3">Artist Statement</h3>
            </Stack>
            <Stack my={15} mb={'3%'} w={'50%'} textAlign={'center'}>
                <p className="text-body2">{statement}</p>
            </Stack>
            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <img src={sign ? sign : signature} height={50} width={100} />
                <Link to={ROUTES.ARTIST.DETAIL.replace(":id", artistDetails?._id as string)} style={{textDecoration: 'none'}}>
                    <Stack ml={10} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Stack h={[10, 10, 15, 30, 50]} w={[10, 10, 15, 30, 50]} >
                            <img src={artistDetails?.thumbnail} height={'100%'} width={'100%'} style={{ borderRadius: '50%' }} />
                        </Stack>
                        <Stack ml={10}>
                            <p className="text-body1">By {artistDetails?.name}</p>
                        </Stack>
                    </Stack>
                </Link>
            </Stack>
        </div>
    )
}

export const ArtistBannerCard = ({ image, sign }: any) => {
    return (
        <div className="artist-banner-card-container">
            <div className="gradiant" />
            <div className="artist-banner-image-container">
                <img src={image} />
            </div>
            <Stack w={'15%'} zIndex={100} alignItems={'center'} alignSelf={'center'} width={'50%'} display={'flex'} justifyContent={'space-between'} flexDirection={'row'}>
                <BiLogoSpotify size={30} color={'rgba(84, 255, 201, 1)'} />
                <BiLogoFacebook size={30} color={'rgba(84, 255, 201, 1)'} />
                <BiLogoInstagram size={30} color={'rgba(84, 255, 201, 1)'} />
                <SiGooglechat size={30} color={'rgba(84, 255, 201, 1)'} />
                <LiaShareAltSolid size={30} color={'rgba(84, 255, 201, 1)'} />
            </Stack>
        </div>
    )
}