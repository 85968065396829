import { Button, Flex } from '@chakra-ui/core';
import React, { FC, useRef, useState } from 'react';
import { Account } from '../../../../assets/icons';
import './style.scss';

interface UploadCardStepProps {
    handleSubmit: (values: any) => void;
    handleBack: () => void;
    formData: any
}

const UploadCardStep: FC<UploadCardStepProps> = ({ handleSubmit, handleBack, formData }) => {

    const [frontProfile, setFrontProfile] = useState(formData.idFront ? formData.idFront : [])
    const [frontProfileLoaded, setFrontProfileLoaded] = useState(formData.idFront ? true : false)
    const [backProfileLoaded, setBackProfileLoaded] = useState(formData.idBack ? true : false)
    const [backProfile, setBackProfile] = useState(formData.idBack ? formData.idBack : [])
    const [frontPreview, setFrontPreview] = useState(formData.idFront ? URL.createObjectURL(formData.idFront) : '')
    const [backPreview, setBackPreview] = useState(formData.idBack ? URL.createObjectURL(formData.idBack) : '')

    const frontRef = useRef<HTMLInputElement>(null);
    const backRef = useRef<HTMLInputElement>(null);
    return (
        <div className="upload-content margin-auto d-flex flex-column">
            <div className="recognition-title text-white text-helvetica">
                Please upload photo of Front and back of Identity Card:
            </div>
            <div className="recognition-content text-white text-gemunu">
                <ul>
                    <li>
                        Upload a color image of entire document.
                    </li>
                    <li>
                        Screenshot are not allowed.
                    </li>
                    <li>
                        JPEG, JPG and PNG format only.
                    </li>
                </ul>
            </div>
            <Flex>
                <div className="d-flex text-helvetica">
                    <div className="upload-box">
                        <p>
                            Front of ID Card here:
                        </p>
                        <button className="upload-btn upload-card" onClick={() => frontRef.current?.click()}>Choose file</button>
                        <input className="absolute hide" type="file" id="avatar"
                            name="frontProfile"
                            ref={frontRef}
                            accept='image/*'
                            onChange={(e: any) => {
                                setFrontProfileLoaded(true)
                                setFrontProfile(e.target.files[0])
                                setFrontPreview(URL.createObjectURL(e.target.files[0]))
                            }}
                        />
                    </div>
                    <div className="upload-box">
                        <p>
                            Correct example:
                        </p>
                        <div className="example-card upload-card d-flex justify-center">
                            {frontPreview ? <img src={frontPreview} /> : <Account className="margin-auto center" />}
                        </div>
                    </div>
                </div>
                <div className="d-flex text-helvetica">
                    <div className="upload-box">
                        <p>
                            Back of ID Card here:
                        </p>
                        <button className="upload-btn upload-card" onClick={() => backRef.current?.click()}>Choose file</button>
                        <input className="absolute hide"
                         type="file" id="avatar"
                            name="backProfile"
                            ref={backRef}
                            accept='image/*'
                            onChange={(e: any) => {
                                setBackProfileLoaded(true)
                                setBackProfile(e.target.files[0])
                                setBackPreview(URL.createObjectURL(e.target.files[0]))
                            }}
                        />
                    </div>
                    <div className="upload-box">
                        <p>
                            Correct example:
                        </p>
                        <div className="example-card upload-card d-flex justify-center">
                            {backPreview ? <img src={backPreview} /> : <Account className="margin-auto center" />}
                        </div>
                    </div>
                </div>
            </Flex>
            <div className='stage-actions'>
                <button className="btn outlined text-gemunu" onClick={handleBack}>Back</button>
                <Button className="btn contained text-gemunu" isDisabled={!frontProfileLoaded || !backProfileLoaded} onClick={() => handleSubmit({ idFront: frontProfile, idBack: backProfile })}>Next</Button>
            </div>

        </div>
    );
};

export default UploadCardStep;
