import { Avatar, useToast } from '@chakra-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { CartApi } from '../../../apis';
import { Alert, IconButton } from '../../../components';
import { ROUTES } from '../../../constants';
import { ProductModel } from '../../../shared/models';
import { addToCart } from '../../../store/actions';
import { getUser } from '../../../store/selectors';
import './styles.scss';

interface IProductProps {
  product: ProductModel;
}

const Product: React.FC<IProductProps> = ({ product }) => {
  const dispatch = useDispatch();

  const toast = useToast();
  const { t } = useTranslation();

  const user = useSelector(getUser);

  const handleRemove = (product: ProductModel) => {
    if (user) {
      CartApi.removeFromCart({
        productId: product.id as string,
        fingerprint: user.id,
        userId: user.id
      }).then((res) => {
        dispatch(addToCart(res.cart));
        toast({
          position: 'top-right',
          render: ({ onClose }) => (
            <Alert
              message={t('Message.Product was removed from your cart!', { Product: product.name })}
              onClose={onClose}
            />
          )
        });
      });
    }
  };

  return (
    <div className="cart-product">
      <div className="content-1">
        <Link to={`${ROUTES.PRODUCTS.PREFIX}/${product.id}`}>
          <Avatar className="product-image" src={product.getAvatarUrl} />
        </Link>
        <Link to={`${ROUTES.PRODUCTS.PREFIX}/${product.id}`}>
          <span className="text-heading4 product-name">{product.name}</span>
        </Link>
      </div>
      <div className="content-2">
        <span className="text-heading4 product-price">{product.currency}{product.price}</span>
        <IconButton icon="remove" className="remove-button" onClick={() => handleRemove(product)} />
      </div>
    </div>
  );
};

export default Product;
