import { Stack, useToast } from '@chakra-ui/core';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ArtistApi, AuthApi, GalleryApi, ProductApi, UserApi } from '../../../apis';
import { Alert } from '../../../components';
import { IArtist } from '../../../shared/interfaces';
import './styles.scss';
import { CardSocialDescription, CommunityEventImageCard, DetailsSection, PrimaryCard } from '../../../components/Card/BannerProductCard';
import { ArtistBannerCard } from '../../../components/Card/ArtistCard';
import { PrimaryButton } from '../../../components/Button/PrimaryButton';
import { CommonCardOutlet } from '../../../components/Card/CommonCardOutlet';
import { ProductModel, UserModel } from '../../../shared/models';
import { PRODUCT_TYPE } from '../../../shared/enums';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../store/selectors';
import { setUser } from '../../../store/actions';
import { CommonProductCard } from '../../../components/Card/ProductCard';
import { getProductRowNumber } from '../../../constants/breakpoint';
import { IGallery } from '../../../shared/interfaces/gallery.interface';

const tabData = [
  {
    value: 0,
    label: 'Overview',
    status: 'Overview'
  },
  {
    value: 1,
    label: 'Galleries',
    status: 'Galleries'
  },
  {
    value: 2,
    label: 'Event Tickets',
    status: 'Event Tickets'
  },
  {
    value: 3,
    label: 'Favourited',
    status: 'Favourited'
  },

];
const pageLimit = 12;

export const ArtistDetailPage: FC = () => {
  const [artist, setArtist] = useState<IArtist>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCnt, setPageCnt] = useState(0);
  const [galleries, setGalleries] = useState([]);
  const [products, setProducts] = useState<ProductModel[]>([])
  const [tabId, setTabId] = useState<number>(0);
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [windowWidth, setWindowWidth] = useState<number>()
  const [productsToShow, setProductsToShow] = useState(3); // Default number of products to show

  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const user = useSelector(getUser)
  const dispatch = useDispatch()

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setWindowWidth(windowWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setProductsToShow(getProductRowNumber(windowWidth))
  }, [windowWidth])

  const fetchProduct = useCallback(() => {
    GalleryApi.getGalleriesByArtist(id, {
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit,
      isFeatured: true
    })
      .then((res) => {
        setPageCnt(Math.ceil(res.pagination.total / pageLimit));
        setGalleries(res.galleries);
      })
      .catch((err) => {
        console.log(err);
      });
    ProductApi.readAll({
      query: {
        artistId: id
      }
    }).then(res => setProducts(res.products))
  }, []);

  useEffect(() => {
    if (artist) {
      fetchProduct();
      if (user) {
        const isExists = user?.subscribedArtist?.includes(id)
        setIsSubscribed(isExists ? isExists : false)
      }
    }
  }, [artist, pageNumber, fetchProduct]);

  useEffect(() => {
    ArtistApi.read(id)
      .then((res) => {
        setArtist(res.artist);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, fetchProduct]);

  const fetchUser = () => {
    AuthApi.me().then(res => {
      const user = new UserModel(res.user);
      if (user) {
        dispatch(setUser(user));
      }
    }).catch(e => console.log("e>", e))
  }
  const handleSubscribe = () => {
    if (user) {
      UserApi.toggleSubscribe({
        userId: user.id,
        artistId: id as string
      }).then(res => {
        if (res) {
          fetchUser()
          setIsSubscribed(!isSubscribed)
        }
      }).catch(e => console.log("e>>>", e))
    } else {
      toast({
        position: 'top-right',
        render: ({ onClose }) => (
          <Alert message={t('Please login first')} onClose={onClose} />
        )
      });
    }
  }
  const galleryAddress: any = []
  galleries.map((gallery: IGallery) => {
    if (gallery.isPublic) {
      galleryAddress.push(gallery.id)
    }
  })
  const filteredGalleries = isSubscribed ? galleries : galleries.filter((gallery: IGallery) => gallery.isPublic)
  const filteredProduct = isSubscribed ? products : products.filter(p => galleryAddress.includes(p?.galleryId as string)).slice(0, productsToShow)
  const filteredEventProduct = isSubscribed ? products.filter(item => item.type === PRODUCT_TYPE.VIRTUAL_EVENT) : products.filter(p => galleryAddress.includes(p?.galleryId as string)).filter(item => item.type === PRODUCT_TYPE.VIRTUAL_EVENT).slice(0, productsToShow)
  return (
    <div className="artist-detail-page">
      <div className="artist-hero-container">
        <div className="left-container">
          <CardSocialDescription
            title={artist?.name}
            description={artist?.bio}
            isArtistPage={true} />
          <PrimaryButton mt={5} fontSize={18} scheme={'primary'} onClick={handleSubscribe}>{isSubscribed ? 'Unsubscribe' : 'Subscribe'}</PrimaryButton>
        </div>
        <div className="right-container">
          <Stack w={'100%'} h={[400, 400, 400, 500, 700]}>
            <ArtistBannerCard image={artist?.thumbnail} />
          </Stack>
        </div>
      </div>
      <div className='tab-button-container'>
        {tabData.map(item => {
          return (
            <PrimaryButton mx={'3%'} fontSize={18} scheme={item.value === tabId ? 'primary' : 'basic'} onClick={() => setTabId(item.value)}><p className='text-body1'>{item.label}</p></PrimaryButton>
          )
        })}
      </div>
      <div className='tab-content-container'>
        {tabId === 1 &&
          <div className='galleries'>
            {filteredGalleries.map(({ _id, name, thumbnail }, index) => (
              <Stack h={[400, 400, 400, 450, 550]}>
                <CommonCardOutlet image={thumbnail} id={_id as string} artistId={id} productType={'gallery'} type="collection">
                  <Stack h={'100%'} w='100%'>
                    <Stack w='100%' display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                      <Stack w='50%'> <p className='text-body1'>{name}</p> </Stack>
                    </Stack>
                  </Stack>
                </CommonCardOutlet>
              </Stack>
            ))}
          </div>
        }
        {tabId === 2 &&
          <Stack w={'100%'}>
            <div className='galleries'>
              {filteredEventProduct.filter(item => item.type === PRODUCT_TYPE.VIRTUAL_EVENT).map((product) => (
                <Stack h={[400, 400, 400, 450, 550]}>
                  <CommonCardOutlet image={product.thumbnail.url} id={product.id as string} productType={product.type} type="product">
                    <DetailsSection details={product} />
                  </CommonCardOutlet>
                </Stack>
              ))}
            </div>
            {!isSubscribed && <Stack w='100%' mt={'5%'} alignItems={'center'} bg={'grey.400'}>
              <h1 className='text-heading3'>
                Subscribe other artist to see the product
              </h1>
              <PrimaryButton scheme="primary" mt={5} onClick={handleSubscribe}>
                Subscribe
              </PrimaryButton>
            </Stack>}
          </Stack>}
        {tabId === 0 &&
          <Stack w={'100%'}>
            <div className='galleries'>
              {filteredProduct.map((product) => (
                <Stack height={500}>
                  <Stack h={[400, 400, 400, 450, 550]} alignItems={'center'}>
                    {product.type === PRODUCT_TYPE.VIDEOS ? <PrimaryCard details={product} /> : product.type === PRODUCT_TYPE.VIRTUAL_EVENT ? <CommunityEventImageCard details={product} /> : <CommonProductCard product={product} />}
                  </Stack>
                </Stack>
              ))}
            </div>
            {!isSubscribed && <Stack w='100%' mt={'5%'} alignItems={'center'} bg={'grey.400'}>
              <h1 className='text-heading3'>
                Subscribe other artist to see the product
              </h1>
              <PrimaryButton scheme="primary" mt={5} onClick={handleSubscribe}>
                Subscribe
              </PrimaryButton>
            </Stack>
            }
          </Stack>
        }
      </div>
    </div>
  );
};
