import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';

import { AuthApi, GalleryApi, ProductApi, UserApi } from '../../../apis';
import { Alert, AnimationOnScroll, Pagination, ProductCard } from '../../../components';
import { IProduct, ISortOrder } from '../../../shared/interfaces';
import { IGallery } from '../../../shared/interfaces/gallery.interface';
import { ProductModel, UserModel } from '../../../shared/models';
import './styles.scss';
import { PRODUCT_TYPE } from '../../../shared/enums';
import { CommunityEventImageCard, PrimaryCard } from '../../../components/Card/BannerProductCard';
import { CommonProductCard } from '../../../components/Card/ProductCard';
import { Stack, useToast } from '@chakra-ui/core';
import { getProductRowNumber } from '../../../constants/breakpoint';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../store/selectors';
import { setUser } from '../../../store/actions';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../components/Button/PrimaryButton';

const CntPerPage = 15;

export const GalleryDetailPage: FC = () => {
  const [gallery, setGallery] = useState<IGallery>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCnt, setPageCnt] = useState(0);
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<ISortOrder>(ISortOrder.ASC);
  const [windowWidth, setWindowWidth] = useState<number>()
  const [productsToShow, setProductsToShow] = useState(3); 

  const { galleryId, id } = useParams<{ galleryId: string, id: string }>();
  const user = useSelector(getUser);
  const [isSubscribed, setIsSubscribed] = useState<any>(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  useEffect(() => {
    const subscribed = user?.subscribedArtist?.includes(id as string)
    setIsSubscribed(subscribed)
  }, [user])
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setWindowWidth(windowWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setProductsToShow(getProductRowNumber(windowWidth))
  }, [windowWidth])
  const toast = useToast();
  const fetchUser = () => {
    AuthApi.me().then(res => {
      const user = new UserModel(res.user);
      if (user) {
        dispatch(setUser(user));
      }
    }).catch(e => console.log("e>", e))
  }


  const handleSubscribe = () => {
    if (user) {
      UserApi.toggleSubscribe({
        userId: user.id,
        artistId: id as string
      }).then(res => {
        fetchUser()
        setIsSubscribed(!isSubscribed)
        console.log("res>>", res)
      }
      ).catch(e => console.log("e>>>", e))
    } else {
      toast({
        position: 'top-right',
        render: ({ onClose }) => (
          <Alert message={t('Please login first')} onClose={onClose} />
        )
      });
    }
  }

  const fetchProducts = useCallback(() => {
    ProductApi.readAll({
      query: {
        galleryId
      },
      options: {
        limit: CntPerPage,
        skip: (pageNumber - 1) * CntPerPage,
        sort: {
          price: sortOrder
        }
      }
    })
      .then((res) => {
        const { products, pagination } = res;
        setProducts(products.map((product: IProduct) => new ProductModel(product)));
        setTotalCnt(pagination.total);
      })
      .catch((err) => {
        console.log('🚀 ~ file: index.tsx:91 ~ fetchProducts ~ err:', err);
      });
  }, [pageNumber, sortOrder]);

  useEffect(() => {
    if (galleryId) fetchProducts();
  }, [galleryId, fetchProducts]);

  useEffect(() => {
    GalleryApi.readById(galleryId).then((res) => {
      setGallery(res.gallery);
    });
  }, [galleryId]);
  const filteredProduct = isSubscribed ? products : products.slice(0, productsToShow)
  return (
    <div className="artist-detail-page">
      <div className="banner-wrapper">
        <div className="banner-container">
          <Carousel autoPlay={true} showThumbs={false} showStatus={false} showArrows={false} infiniteLoop={true}>
            {new Array(3).fill(1).map((item, index) => (
              <img key={index} src="/images/artists/banner.png" alt="banner" />
            ))}
          </Carousel>
        </div>
        <div className="artist-image-wrapper">
          <div className="artist-image-container">
            <img src={gallery?.thumbnail} alt="artist_thumbnail" />
          </div>
        </div>
      </div>
      <div className="artist-detail-container">
        <div className="artist-info text--white">
          <div className="artist-header-container">
            <h1 className="text-heading2">{gallery?.name}</h1>
          </div>
          <p className="artist-joined-at text-body4"> {moment(gallery?.createdAt).format('DD MMMM YYYY')}</p>
          <p className="artist-description text-body3">{gallery?.description}</p>
        </div>
      </div>

      <div className="content">
        <div className="products">
          {filteredProduct.length > 0 &&
            filteredProduct.map((product) => (
              <Stack h={[400, 400, 400, 450, 550]}>
                {product.type === PRODUCT_TYPE.VIDEOS ? <PrimaryCard details={product} /> : product.type === PRODUCT_TYPE.VIRTUAL_EVENT ? <CommunityEventImageCard details={product} /> : <CommonProductCard product={product} />}
              </Stack>
            ))}
        </div>
        {!isSubscribed && <Stack w='100%' mt={'5%'} alignItems={'center'} bg={'grey.400'}>
          <h1 className='text-heading3'>
            Subscribe other artist to see the product
          </h1>
          <PrimaryButton scheme="primary" mt={5} onClick={handleSubscribe}>
            Subscribe
          </PrimaryButton>
        </Stack>}
        <Pagination pageCnt={pageCnt} activePage={pageNumber} onChange={setPageNumber} />
      </div>
    </div>
  );
};
