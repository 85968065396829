import React, { FC, useState } from "react";
import './styles.scss'
import { CartButton } from "../../Button/CartButton";
import { CURRENCY, PRODUCT_TYPE } from "../../../shared/enums";
import { PrimaryButton } from "../../Button/PrimaryButton";
import { ProductModel } from "../../../shared/models";
import { REACT_APP_API_ASSET_SERVER, REACT_APP_ENVIRONMENT, ROUTES } from "../../../constants";
import { Button, Stack, useToast } from "@chakra-ui/core";
import Quotes from "../../../assets/images/“.png"
import { IoChevronForwardOutline, IoClose, IoCopy } from "react-icons/io5";
import { ObjectRender } from "../../ObjectRender";
import { FavouriteButton } from "../../Button/FavouriteButton";
import { Audio } from "../../SeekbarAudio";
import { Link } from "react-router-dom";
import { BiSolidShareAlt } from "react-icons/bi";
import moment from "moment";
import CustomVideo from "../../VideoPlayer";
import { IArtist } from "../../../shared/interfaces";
import { SiFacebook, SiTwitter } from "react-icons/si";
import { Alert } from "../../Alert";
import { copyTextToClipboard } from "../../../utils";

interface IDetailsSection {
    details: ProductModel
    isPrimaryCard?: boolean
}
export const DetailsSection = ({ details, isPrimaryCard }: IDetailsSection) => {
    const [openShare, setOpenShare] = useState<boolean>(false);
    const toast = useToast();
    const productType = details?.type
    let route: any
    if (productType === PRODUCT_TYPE.IMAGES) {
        route = ROUTES.IMAGES.INDEX
    } else if (productType === PRODUCT_TYPE.MERCHANDISE) {
        route = ROUTES.MERCHANDISE.INDEX
    } else if (productType === PRODUCT_TYPE.OBJECT) {
        route = ROUTES.OBJECTS.INDEX
    } else if (productType === PRODUCT_TYPE.VIDEOS) {
        route = ROUTES.VIDEOS.INDEX
    } else if (productType === PRODUCT_TYPE.VIRTUAL_EVENT) {
        route = ROUTES.EVENTS.INDEX
    } else if (productType === PRODUCT_TYPE.SONG) {
        route = ROUTES.SONGS.INDEX
    } else {
        route = ROUTES.SONGS.INDEX
    }

    const onCopy = () => {
        copyTextToClipboard(`https:/www.dmusic.com/${route}${details?.id}` as string)
        toast({
            position: 'top-right',
            render: ({ onClose }) => (
                <Alert message={'Copied to Clipboard'} onClose={onClose} />
            )
        });
    }
    const style = isPrimaryCard ? 'primary' : 'common'
    return (
        openShare ?
            <Stack height={'100%'} display={'flex'} zIndex={100} justifyContent={'space-between'} alignItems={'end'}>
                <IoClose onClick={() => setOpenShare(false)} cursor={'pointer'} size={25} color="white" />
                <Stack w={'100%'} justifyContent={'space-between'} display={'flex'} flexDirection={'row'}  zIndex={100}>
                    <p style={{ width: '70%', textOverflow: 'ellipsis', overflow: 'hidden' }}>{`https:/www.dmusic.com/${route}/${details?.id}`}</p>
                    <IoCopy cursor={'pointer'} size={15} color="white" onClick={onCopy} />
                </Stack>
                <PrimaryButton scheme="basic" w='100%'> <SiFacebook /> <p className="text-body1">FACEBOOK</p></PrimaryButton>
                <PrimaryButton scheme="basic" w='100%'> <SiTwitter /> <p className="text-body1">Twitter</p></PrimaryButton>
            </Stack> :
            <Stack height={'100%'} justifyContent={'space-between'}>
                {details?.type == PRODUCT_TYPE.SONG ?
                    <Stack height={'20%'} justifyContent={'center'}>
                        {(details?.id !== 'undefined' && details?.music?.url) && <Audio src={details?.music?.url} />}
                    </Stack> : null}
                <div className={`${style}-detail-section-container`} style={{ height: details?.type == PRODUCT_TYPE.SONG ? '80%' : '100%' }}>
                    <div className={`${style}-details-section`}>

                        <Link className={`${style}-product-details-section`} style={{ textDecoration: 'none' }} to={`${route}/${details?.id}`}>
                            <p className="text-heading4">{details?.name}</p>
                            {details?.type === PRODUCT_TYPE.VIRTUAL_EVENT ? null :
                                <Stack alignItems={'center'} display={'flex'} flexDirection={'row'}><p className="text-heading4" style={{ marginLeft: 5 }}>{details?.currency}{details?.price}</p></Stack>
                            }
                        </Link>
                        <div className={`${style}-icon-container`}>
                            <FavouriteButton product={details} />
                            <BiSolidShareAlt size={25} color="white" onClick={() => setOpenShare(!openShare)} cursor={'pointer'} />
                        </div>
                    </div>
                    <div className={`${style}-button-section`}>
                        {details?.artistDetails ?
                            <Link className={`${style}-artist-detail-section`} style={{ textDecoration: 'none' }} to={`${ROUTES.ARTIST.LIST}/${details?.artistDetails?._id}`}>
                                <div className={`${style}-artist-image`}>
                                    <img src={details?.artistDetails?.thumbnail} />
                                </div>
                                <p className="text-body1"> by {details?.artistDetails?.name}</p>
                            </Link>
                            : <div></div>}
                        {details?.type === PRODUCT_TYPE.VIRTUAL_EVENT ?
                            <PrimaryButton scheme="secondary"><p className="text-body1 text--black">ATTEND</p></PrimaryButton> : <CartButton
                                color={'lime'}
                                productId={details?.id as string}
                                productPrice={details?.price as number}
                                productCurrency={details?.currency as CURRENCY}
                            />}
                    </div>
                </div>
            </Stack>
    )
}

export const PrimaryCard = ({ details }: IDetailsSection) => {

    return (
        <div className="primary-card-container">
            <div className="gradiant-top" />
            <div className="gradiant-bottom" />
            <div className="product-image">
                {details?.object ?
                    <ObjectRender id={details?.id} canAction fieldname={details?.object?.fieldname} filename={details.object.filename} /> :( details?.type === 'video' || details?.type === 'product')?
                        <CustomVideo videoUrl={details.video?.url} /> :
                        <img height='100%' width={'100%'} src={`${REACT_APP_API_ASSET_SERVER}/${details?.thumbnail.fieldname}/${details?.thumbnail.filename}`} />
                }
            </div>
            <div className="product-details">
                <DetailsSection details={details} isPrimaryCard={true} />
            </div>
        </div>
    )
}

interface ICardSocialDescription {
    title?: string;
    isArtistPage?: boolean;
    subTitle?: string;
    description?: string;
    tags?: Array<string>;
    sign?: any;
    artistDetails?: IArtist;
}
export const CardSocialDescription: FC<ICardSocialDescription> = ({ title, tags, isArtistPage = false, subTitle, description, sign, artistDetails }) => {
    return (
        <div className="card-social-description-container">
            <div className="tag-container">
                {tags?.map((tag) => {
                    return (
                        <PrimaryButton fontSize={18} scheme='primary'><p className="text-body1">{tag}</p></PrimaryButton>
                    )
                })}
            </div>
            <div className="description">
                <h1 className="text-heading1">
                    {title}
                </h1>
                <h2 className="text-heading2">
                    {subTitle}
                </h2>
                <p className="text-body1">{description}</p>
            </div>
            <div className="divider" />
            {!isArtistPage && <div className="social-description">
                <Link to={ROUTES.ARTIST.DETAIL.replace(":id", artistDetails?._id as string)} style={{ display: 'flex', textDecoration: 'none', alignItems: 'center' }}>
                    <Stack  h={[10, 10, 15, 30, 50]} w={[10, 10, 15, 30, 50]} borderRadius={20} display={'flex'} flexDirection={'row'} alignItems={'center'} mr={10}>
                        <img src={artistDetails?.thumbnail} style={{ height: '100%', width: '100%', borderRadius: '50%' }} />
                    </Stack>
                    <p className="text-body1">By {artistDetails?.name}</p>
                </Link>
            </div>}
        </div>
    )
}


export const CommunityEventImageCard = ({ details }: IDetailsSection) => {
    return (
        <div className="primary-card-container">
            <div className="gradiant-top" />
            <div className="gradiant-bottom" />
            <Stack w='100%' display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                <Stack display={'flex'} flexDirection={'row'} width={'70%'} flexWrap={'wrap'}>
                    <PrimaryButton scheme="primary"><p className="text-body1">{moment(details?.startTime).format("DD.MM.YYYY")}</p></PrimaryButton>
                    <PrimaryButton ml={[1, 0, 1, 6]} px={[2, 0, 2, 2]} scheme="primary"><p className="text-body1">{moment(details?.startTime).format("HH A")}</p></PrimaryButton>
                </Stack>
                <Stack w={'30%'} alignItems={'end'}>
                    <PrimaryButton scheme="primary"><p className="text-body1">{details?.price} {details?.currency}</p></PrimaryButton>
                </Stack>
            </Stack>

            <div className="product-image">
                <CustomVideo videoUrl={details?.video?.url} />
            </div>

            <div className="product-details">
                <DetailsSection details={details} isPrimaryCard={true} />
            </div>
        </div>
    )
}
interface ICommunityEventDescriptionCard {
    tags: string[] | any;
    title?: string;
    subTitle?: string;
    description?: string;
    artistDetails?: IArtist;
}
export const CommunityEventDescriptionCard: FC<ICommunityEventDescriptionCard> = ({ tags, artistDetails, title, subTitle, description }) => {
    return (
        <div className="community-event-description-container">
            <div className="tag-container">
                {tags && tags.map((tag: string) => {
                    return (
                        <PrimaryButton fontSize={18} scheme='primary'>{tag}</PrimaryButton>
                    )
                })}
            </div>
            <div className="description">
                <h1 className="text-heading1">
                    {title}
                </h1>
                <h2 className="text-heading2">
                    {subTitle}
                </h2>
                <Stack display={'flex'} mt={27} mb={52} w={'100%'} flexDirection={'row'}>
                    <PrimaryButton rightIcon={<IoChevronForwardOutline />} mr={10} scheme='secondary'><p className="text-body1 text--black">Attend</p></PrimaryButton>
                    <PrimaryButton rightIcon={<IoChevronForwardOutline />} scheme='basic'><p className="text-body1">View</p></PrimaryButton>
                </Stack>
                <img src={Quotes} />
                <Stack w={['100%', '100%', '60%', '60%', '60%']}>
                <p className="text-body1">{description}</p>
                </Stack>
                <div className="divider" />
                <Stack display={'flex'} flexDirection={'row'} mt={5} width={'100%'} >
                    <Stack display={'flex'} ml={'5%'} flexDirection={'row'} alignItems={'center'}>
                        <Stack   h={[10, 10, 15, 30, 50]} w={[10, 10, 15, 30, 50]}  borderRadius={20} display={'flex'} flexDirection={'row'} alignItems={'center'} mr={10}>
                            <img src={artistDetails?.thumbnail} style={{ height: '100%', width: '100%', borderRadius: '50%' }} />
                        </Stack>
                        <p className="text-body1">By {artistDetails?.name}</p>
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}