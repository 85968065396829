import React, { useEffect } from 'react';
import './style.scss';

export interface IYearListBody {
  yearProp?: number,
  setYearCallback?: (year: number) => void,
}

function YearListBody ({
  yearProp = new Date().getFullYear(),
  setYearCallback = () => {},
 }: IYearListBody) {
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  
  useEffect(() => {
    console.log(yearProp);
  }, [yearProp]);
  
  const setYear = (year: number) => {
    setYearCallback(year);
  }
  
  return (
    <div className="year-list">
      { items.reverse().map((item, index) =>
        <div
          key={`listItem-${index}`}
          className="day-list color-white"
          onClick={() => setYear(yearProp - item)}
        >
           {yearProp - item}
        </div>
      )}
      
      <div
        className="current-year color-white"
        onClick={() => setYear(yearProp)}
      >
        {yearProp}
      </div>
      { items.map((item, index) =>
        <div
          key={`listItem-${index}`}
          className="day-list color-white"
          onClick={() => setYear(yearProp + item)}
        >
          {yearProp + item}
        </div>
      )}
    
    </div>
  );
}

export default YearListBody;
