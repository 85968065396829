import { Stack, useToast } from '@chakra-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { ProductApi } from '../../apis';
import { Alert } from '../../components';
import { ROUTES } from '../../constants';
import { PRODUCT_TYPE } from '../../shared/enums';
import { ProductModel } from '../../shared/models';
import { TabTitle, metaTagByDesc, metaTagByKey, metaTagByTitle, metaTagByWeb } from '../../utils/generaltittlefunction';
import './styles.scss';
import { CardSocialDescription, DetailsSection, PrimaryCard } from '../../components/Card/BannerProductCard';
import { CommonCardOutlet } from '../../components/Card/CommonCardOutlet';
import { useParams } from 'react-router-dom';

export const ObjectsPage: FC = () => {
  const [product, setProduct] = useState<ProductModel>();
  const [similarProducts, setSimilarProducts] = useState<ProductModel[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  // Get product id from hook
  const { id: productId } = useParams<{ id: string }>();

  const toast = useToast();
  const { t } = useTranslation();

  const history = useHistory()
  // Data fetcher
  const fetchData = (productId: string) => {
    setLoading(true)
    ProductApi.read(productId)
      .then((res) => {
        setProduct(res.product);
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        toast({
          position: 'top-right',
          render: ({ onClose }) => <Alert message={err.msg} color="red" onClose={onClose} />
        });
      });
    ProductApi.readAll({ query: { type: PRODUCT_TYPE.OBJECT } })
      .then((res) => {
        setLoading(false)
        setSimilarProducts(res.products);
      })
      .catch((err) => {
        setLoading(false)
        console.log("err=>", err);
      });
  };

  // On product id changed
  useEffect(() => {
    fetchData(productId);
    // eslint-disable-next-line
  }, [productId]);


  if (history.location.pathname === ROUTES.IMAGES.INDEX) {
    TabTitle(t('Common.Images - Digital Music Shopping Market Place'));
  }

  if (history.location.pathname === ROUTES.IMAGES.INDEX) {
    metaTagByTitle(t('Common.Images - Digital Music Shopping Market Place'));
  }
  metaTagByDesc(
    t('Common.D-Music is founded on values we all share and are ready to stand for.') +
    ' ' +
    t('Common.They bring us together well beyond our current products and technologies.') +
    ' ' +
    t(
      'Common.They’ve defined our identity since the beginning, and they’ll continue to do so, no matter how our business evolves.'
    )
  );
  metaTagByKey(t('Common.Dmusic, Nft, Hackers, Explore Through the Most Exciting Music NFT'));
  metaTagByWeb(`https://dev.dmusic.io${window.location.pathname}`);
  return (
    <div className="object-container">
      <div className="crypto-art-container">
        <div className="right-container">
          <CardSocialDescription
            tags={product?.category.map((cat) => cat.name)}
            sign={product?.sign?.url}
            title="Object Art"
            subTitle={product?.name}
            artistDetails={product?.artistDetails}
            description={product?.description}
          />
        </div>
        <div className="left-container" style={{ justifyContent: 'end' }}>
          <div className='card'>
            {/*  eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <PrimaryCard details={product} />
          </div>
        </div>
      </div>
      <Stack mt={'3%'} width={'80%'} alignSelf={'left'}>
        <h1 className="text-heading1">Trending Object</h1>
      </Stack>
      <div className="similar-track-container">
        {similarProducts?.map((item) => {
          return (
            <Stack  h={[400, 400, 400, 450, 550]}>
              <CommonCardOutlet image={item.getThumbnailUrl} id={item.id as string} type="product" object={item.object} productType={item.type}>
                <DetailsSection details={item}/>
              </CommonCardOutlet>
            </Stack>
          )
        })}
      </div>
    </div>
  );
};
