import React, { FC, useRef, useState } from 'react';
import './style.scss';
import { Account, AccountPerson } from '../../../../assets/icons';
import { KycApi } from '../../../../apis';
import { Button, useToast } from '@chakra-ui/core';
import { Alert } from '../../../../components';

interface FaceRecognitionStepProps {
    handleSubmit: (values: any) => void;
    handleBack: () => void;
    formData: any;
}

const FaceRecognitionStep: FC<FaceRecognitionStepProps> = ({ handleSubmit, handleBack, formData }) => {
    const ref = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState('')
    const toast = useToast();
    const [frontPreview, setFrontPreview] = useState('')

    const handleFileUpload = () => {
        ref.current?.click();
    };
    const handleFileChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file);
        setFrontPreview(URL.createObjectURL(event.target.files[0]))
    };

    return (
        <div className="recognition">
            <div className="recognition-title text-white text-helvetica">
                Please upload photo of handholding ID Document and Statement:
            </div>
            <div className="recognition-content text-white text-gemunu">
                <ul>
                    <li>
                        Upload a photo of you handholding the front of ID Document and Statement.
                    </li>
                    <li>
                        Please write down the date of submission and the word "DMusic" on the Statement.
                    </li>
                    <li>
                        Please ensure that the ID Certificate and Statement are clearly visible, and the texts on which are completely and fully exposed.
                    </li>
                </ul>
            </div>
            <div className="d-flex text-helvetica">
                <div className="upload-box">
                    <p>
                        Click  to upload:
                    </p>
                    <button className="upload-btn upload-card" onClick={handleFileUpload}>Choose file</button>
                    <input className="absolute hide" type="file" id="avatar"
                        name="avatar"
                        accept='image/*'
                        ref={ref}
                        onChange={handleFileChange}
                    />
                </div>
                <div className="upload-box">
                    <p>
                        Correct example:
                    </p>
                    <div className="example-card upload-card d-flex justify-center">
                        {frontPreview ? <img src={frontPreview}/> :
                            <>
                                <AccountPerson className="account-person" />
                                <Account className="account" />
                                <div className="file-name">
                                    <div>
                                        <p>DMusic</p>
                                        <span>17.02.2023</span>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div className='stage-actions'>
                <button className="btn outlined text-gemunu" onClick={handleBack}>Back</button>
                <Button className="btn contained text-gemunu" isDisabled={file ? false : true} onClick={() => {
                    const newKyc = new FormData();
                    Object.keys(formData).forEach(key => {
                        if (formData[key] instanceof File) {
                            newKyc.append(key, formData[key])
                        }
                        else { newKyc.append(key, formData[key]) }
                    })
                    newKyc.append('faceId', file)
                    KycApi.createKyc(newKyc)
                        .then((res) => {
                            toast({
                                position: 'top-right',
                                render: ({ onClose }) => (
                                    <Alert message={res.msg} color="lime" onClose={onClose} />
                                )
                            });
                            handleSubmit({ faceId: file })
                        })
                        .catch((err) => {
                            console.log("🚀 ~ file: AuditingIDInfoStep.tsx:22 ~ useEffect ~ err:", err)
                        })

                }
                }>Next</Button>
            </div>
        </div>
    );
};

export default FaceRecognitionStep;
