import React, { FC, useEffect, useState } from "react";
import { DmusicAutoScrollTag, HeroHomeBanner, IconLinkSection, Web3Asset } from "../../components/Banner";
import { CardSocialDescription, CommunityEventDescriptionCard, CommunityEventImageCard, PrimaryCard } from "../../components/Card/BannerProductCard";
import WalletConnect from '../../assets/images/WalletConnect.png'
import Safepal from '../../assets/images/SafePal.png'
import MetaMask from '../../assets/images/MetaMask.png'
import Exodus from '../../assets/images/Exudos.png'
import TrustWallet from '../../assets/images/TrustWallet.png'
import { RoundCrousal } from "../../components/RoundCrousal";
import { CollectionCardHome, CollectionImageLayout } from "../../components/Card/CollectionCard";
import { ArticleCard } from "../../components/Card/ArticleCard";
import { TestimonialCard } from "../../components/Card/TestimonialCard";
import { IoChevronForwardOutline } from 'react-icons/io5'
import { UserAdd, WebDesign, Use, Heart } from '../../assets/icons'
import { Stack } from "@chakra-ui/core";
import { DiscoverSection } from "../../components/DiscoverSection";
import { ArticleApi, GalleryApi, ProductApi } from "../../apis";
import { PRODUCT_TYPE } from "../../shared/enums";
import { IArticle } from "../../shared/interfaces";
import { useHistory } from "react-router-dom";
import { REACT_APP_API_ASSET_SERVER, ROUTES } from "../../constants";
import { ProductModel } from "../../shared/models";
import './styles.scss'
import { DmusicHome } from "../../assets/video";
import { useTranslation } from "react-i18next";


const ChainList = [TrustWallet, WalletConnect, Safepal, MetaMask, Exodus]
const LinkList = [
    {
        icon: <UserAdd />,
        title: 'Sign Up',
        description: 'Digital Asset are unique assets stored and tradable on a blockchain. '
    },
    {
        icon: <WebDesign />,
        title: 'Web Design',
        description: 'Digital Asset are unique assets stored and tradable on a blockchain. '
    },
    {
        icon: <Use />,
        title: 'Use',
        description: 'Digital Asset are unique assets stored and tradable on a blockchain. '
    },
    {
        icon: <Heart color="white" />,
        title: 'Gift',
        description: 'Digital Asset are unique assets stored and tradable on a blockchain. '
    }
]
const carouselImages = [
    'https://picsum.photos/2103',
    'https://picsum.photos/2102',
    'https://picsum.photos/2101',
    'https://picsum.photos/2104',
    'https://picsum.photos/2105',
    'https://picsum.photos/2106',
    'https://picsum.photos/2107',
    'https://picsum.photos/2108',
    'https://picsum.photos/2109',
    'https://picsum.photos/2110',
    // Add more image URLs here
];
export const HomePage: FC = () => {
    const [featuredProduct, setFeaturedProduct] = useState<ProductModel[]>([])
    const [images, setImages] = useState<any>(carouselImages)
    const [articles, setArticles] = useState<IArticle[]>([])
    const [nftRapMusic, setNFTRapMusic] = useState<ProductModel[]>([])
    const [collection, setCollection] = useState<any>([])
    const history = useHistory();
    const { t } = useTranslation();
    useEffect(() => {
        GalleryApi.readAllGalleries({
            query: {
                isFeatured: true
            }
        }).then(r => setCollection(r.galleries))
        ProductApi.readAll({
            query: {
                isFeatured: true
            }
        }).then(r => {
            setFeaturedProduct(r.products)
        })
        ProductApi.readAll({
            query: {
            }
        }).then(r => {
            const imgArr = r.products.slice(0, 10).map((item: ProductModel) => `${REACT_APP_API_ASSET_SERVER}/${item?.thumbnail?.fieldname}/${item?.thumbnail?.filename}`)
            setImages(imgArr)
        })
        ProductApi.readAll({
            query: {
                type: PRODUCT_TYPE.SONG,
                isFeatured: true,
                // category: {
                //     $in: '64eca543e4f7949b5d2f03d6'
                // }
            }
        }).then(r => {
            setNFTRapMusic(r.products)
        })
    }, [])

    useEffect(() => {
        ArticleApi.readAll({
            query: {
                isFeatured: true
            },
            options: {
                sort: {
                    updatedAt: 'desc'
                }
            }
        })
            .then((res) => {
                setArticles(res.articles);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);
    return (
        <div className="home-container">
            <div className="home-gradiant-1" />
            <div className="home-gradiant-2" />
            <div className="home-gradiant-3" />
            <div className="hero-container">
                <video src={DmusicHome} style={{ height: 'fit-content', width: '100%' }} controls={false} autoPlay={true} loop={true} muted={true} />
                <div className="left-container">
                    <HeroHomeBanner
                        hero
                        heading="Music for Digital Asset creators."
                        subHeading="Music Digital Asset will continue to revolutionize the way that artists and fans create community together as we enter the upcoming year — undoubtedly changing the trajectory of countless budding music careers. "
                        basicButtonText="Discover More"
                        basicRightIcon={<IoChevronForwardOutline />}
                        onBasicClick={() => history.push(ROUTES.SHOP.INDEX)}
                    />
                </div>
            </div>
            <div className="scroll-container">
                <DmusicAutoScrollTag />
            </div>
            <div className="supported-chain">
                {ChainList.map(item => {
                    return (
                        <div className='image-container'>
                            <img src={item} />
                        </div>
                    )
                })}
            </div>
            <div className="club-house-section">
                <div className="left-container">
                    <HeroHomeBanner
                        heading="Meet DMusic Clubhouse"
                        subHeading="Digital Asset are unique assets stored and tradable on a blockchain. They are, immutable, scarce and publicly verifiable. In the music world, artistes get to sell limited digital content to their fans who pay via crypto. You can now produce any music content that cannot be counterfeited."
                        basicButtonText="Clubhouse"
                        secondaryrButtonText="Events List"
                        onSecondaryClick={() => history.push(ROUTES.EVENTS.INDEX)}
                        onBasicClick={() => history.push(ROUTES.EVENTS.INDEX)}
                        secondaryRightIcon={<IoChevronForwardOutline />}
                        basicRightIcon={<IoChevronForwardOutline />}
                    />
                </div>
                <div className="right-container">
                    <div className='card'>
                        <RoundCrousal images={images} />
                    </div>
                </div>
            </div>
            <div className="link-section">
                <div className="gradiant-link" />
                <div className="gradiant-link-circle" />
                {LinkList.map(item => {
                    return (
                        <IconLinkSection icon={item.icon} title={item.title} description={item.description} />
                    )
                })}
            </div>
            <div className="nft-section">
                <div className="left-section">
                    <div className="nft-card-left-section">
                        <div className="nft-card-section">
                            <CollectionCardHome image={`${REACT_APP_API_ASSET_SERVER}/${nftRapMusic[0]?.thumbnail?.fieldname}/${nftRapMusic[0]?.thumbnail?.filename}`} collectionName={nftRapMusic[0]?.name} price={nftRapMusic[0]?.price as any} id={nftRapMusic[0]?.id} currency={nftRapMusic[0]?.currency} />
                        </div>
                        <div className="nft-card-section" style={{ marginLeft: "15%" }}>
                            <CollectionCardHome image={`${REACT_APP_API_ASSET_SERVER}/${nftRapMusic[1]?.thumbnail?.fieldname}/${nftRapMusic[1]?.thumbnail?.filename}`} collectionName={nftRapMusic[1]?.name} price={nftRapMusic[1]?.price as any} id={nftRapMusic[1]?.id} currency={nftRapMusic[0]?.currency} />
                        </div>
                    </div>
                    <div className="nft-card-right-section">
                        <div className="nft-card-section">
                            <CollectionCardHome image={`${REACT_APP_API_ASSET_SERVER}/${nftRapMusic[2]?.thumbnail?.fieldname}/${nftRapMusic[2]?.thumbnail?.filename}`} collectionName={nftRapMusic[2]?.name} price={nftRapMusic[2]?.price as any} id={nftRapMusic[2]?.id} currency={nftRapMusic[0]?.currency} />
                        </div>
                        <div className="nft-card-section" style={{ marginLeft: "15%" }}>
                            <CollectionCardHome image={`${REACT_APP_API_ASSET_SERVER}/${nftRapMusic[3]?.thumbnail?.fieldname}/${nftRapMusic[3]?.thumbnail?.filename}`} collectionName={nftRapMusic[3]?.name} price={nftRapMusic[3]?.price as any} id={nftRapMusic[3]?.id} currency={nftRapMusic[0]?.currency} />
                        </div>
                    </div>
                </div>
                <div className="right-section">
                    <HeroHomeBanner
                        heading="Collect Digital Asset your favourite Music"
                        subHeading="Create your first music Digital Asset with Digital Asset market for musicians. Create music and music videos just under the $1, the platform works with BSC blockchain and supports mp4. With Digital Asset, you are getting the control over your Digital Asset, no middle man cutting your sales. Create, Buy, Sell and Earn with your music Digital Asset."
                        basicButtonText={t('Home.Join for Free')}
                        secondaryrButtonText="Explore"
                        onSecondaryClick={() => history.push(ROUTES.PRODUCTS.INDEX)}
                        onBasicClick={() => history.push(ROUTES.AUTH.SIGN_IN)}
                        secondaryRightIcon={<IoChevronForwardOutline />}
                        basicRightIcon={<IoChevronForwardOutline />}
                    />
                </div>
            </div>
            <div className="web-asset-container">
                <Web3Asset />
            </div>
            <Stack w={'90%'} alignItems={'start'}>
                <h1 className="text-heading1">News & Articles</h1>
            </Stack>
            <div className="article-section">
                {articles.slice(0, 3).map((item: IArticle) => {
                    return (
                        <div className="card" onClick={() => history.push(ROUTES.ARTICLE.DETAIL.replace(':id', item.id))}>
                            <ArticleCard image={`${REACT_APP_API_ASSET_SERVER}/${item.thumbnail?.fieldname}/${item.thumbnail?.filename}`} date="56/23/23" title={item.title} description={item.description} id={item.id} />
                        </div>
                    )
                })}
            </div>

            <div className="crypto-art-container">
                <div className="left-container">
                    <div className='card'>
                        <PrimaryCard details={featuredProduct.filter(product => product.type === PRODUCT_TYPE.IMAGES)[0]} />
                    </div>
                </div>
                <div className="right-container">
                    <CardSocialDescription
                        tags={featuredProduct.filter(product => product.type === PRODUCT_TYPE.IMAGES)[0]?.category.map((cat) => cat.name)}
                        sign={featuredProduct.filter(product => product.type === PRODUCT_TYPE.IMAGES)[0]?.sign?.url}
                        title="Image ART"
                        artistDetails={featuredProduct.filter(product => product.type === PRODUCT_TYPE.IMAGES)[0]?.artistDetails}
                        subTitle={featuredProduct.filter(product => product.type === PRODUCT_TYPE.IMAGES)[0]?.name}
                        description={featuredProduct.filter(product => product.type === PRODUCT_TYPE.IMAGES)[0]?.description} />
                </div>
            </div>
            <div className="crypto-art-container">
                <div className="right-container">
                    <CommunityEventDescriptionCard tags={['Digital Asset']} artistDetails={featuredProduct.filter(product => product.type === PRODUCT_TYPE.VIRTUAL_EVENT)[0]?.artistDetails} title="Community Event" subTitle="Digital Asset World" description='Digital Asset are unique assets stored and tradable on a blockchain. They are, immutable, scarce and publicly verifiable. In the music world, artistes get to sell limited digital content to their fans who pay via crypto. ' />
                </div>
                <div className="left-container">
                    <CommunityEventImageCard details={featuredProduct.filter(product => product.type === PRODUCT_TYPE.VIRTUAL_EVENT)[0]} />
                </div>
            </div>
            <div className="crypto-art-container">
                <div className="left-container">
                    <div className='card'>
                        <PrimaryCard details={featuredProduct.filter(product => product.type === PRODUCT_TYPE.VIDEOS)[0]} />
                    </div>
                </div>
                <div className="right-container">
                    <CardSocialDescription
                        tags={featuredProduct?.filter(product => product.type === PRODUCT_TYPE.VIDEOS)[0]?.category.map((cat) => cat.name)}
                        sign={featuredProduct.filter(product => product.type === PRODUCT_TYPE.VIDEOS)[0]?.sign?.url}
                        title="Video Art"
                        artistDetails={featuredProduct.filter(product => product.type === PRODUCT_TYPE.VIDEOS)[0]?.artistDetails}
                        subTitle={featuredProduct.filter(product => product.type === PRODUCT_TYPE.VIDEOS)[0]?.name}
                        description={featuredProduct.filter(product => product.type === PRODUCT_TYPE.VIDEOS)[0]?.description} />
                </div>
            </div>

            <div className="crypto-art-container">
                <div className="right-container">
                    <CardSocialDescription
                        tags={featuredProduct.filter(product => product.type === PRODUCT_TYPE.SONG)[0]?.category.map(cat => cat.name)}
                        sign={featuredProduct.filter(product => product.type === PRODUCT_TYPE.SONG)[0]?.sign?.url}
                        title="Music Art"
                        artistDetails={featuredProduct.filter(product => product.type === PRODUCT_TYPE.SONG)[0]?.artistDetails}
                        subTitle={featuredProduct.filter(product => product.type === PRODUCT_TYPE.SONG)[0]?.name}
                        description={featuredProduct.filter(product => product.type === PRODUCT_TYPE.SONG)[0]?.description} />
                </div>
                <div className="left-container" style={{ justifyContent: 'end' }}>
                    <Stack h={[400, 400, 400, 450, 550]} w={'100%'} alignItems={'center'}>
                        <PrimaryCard details={featuredProduct.filter(product => product.type === PRODUCT_TYPE.SONG)[0]} />
                    </Stack>
                </div>
            </div>
            <div className="collection-section">
                <div className="collection-gradiant" />
                <Stack w={'80%'} mt={'5%'} mb={'5%'} alignItems={'start'}>
                    <h1 className="text-heading1">Collection Featured Digital Asset</h1>
                </Stack>
                <Stack w={['80%', '80%', '80%', '70%']} justifyContent={'space-between'} display={'flex'} flexDirection={'row'}>
                    {collection.slice(0, 3).map((item: any) => {
                        return (
                            <div className="card">
                                <CollectionImageLayout data={item} />
                            </div>
                        )
                    })}
                </Stack>
            </div>

            <div className="discover-section">
                <DiscoverSection />
            </div>
        </div>
    )
}